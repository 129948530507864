import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";

import HeaderComponent from "./components/header";
import { useEffect } from "react";
import LoginViewAutoDesign from "./app/user/features/auth/pages/login-page";
import InstanceList2 from "./app/user/features/dashboard/pages/ilist";
import Master from "./app/user/master";
import InstanceDetails from "./app/user/features/instance_detail/pages/instance_details";
import MasterAdmin from "./app/admin/master";
import DashboardScreen from "./app/admin/features/dashboard/pages/dashboard_screen";
import UserScreen from "./app/admin/features/user/pages/users_screen";
import DataCollection from "./app/admin/features/data-collection/pages/data-collection";
import ImportTab from "./app/user/features/instance_detail/components/tabs/importTab";
import DataCollectionList from "./app/user/features/dashboard/pages/dataCollectionList";
import DataCollectionOld from "./app/admin/features/data-collection/pages/data-collection-old";
import RadarData from "./app/admin/features/data-visualization/pages/RadarData";
import Ambient from "./app/admin/features/data-visualization/pages/Ambient";
import InsightsPage from "./app/user/features/insights/pages/insights";

function App() {
  const route = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const activeUser = sessionStorage.getItem("activeUser");
    if (!activeUser && route.pathname !== "/login") {
      navigate("/login");
    } else if (activeUser) {
      const userRole = JSON.parse(activeUser).role;
      if (route.pathname.startsWith("/admin") && userRole == "user") {
        navigate("/user");
      } else if (route.pathname === "/login") {
        userRole === "admin" || userRole === "dev"
          ? navigate("/admin")
          : navigate("/user");
      } else {
        // navigate("/login");
      }
    }
  }, [navigate, route.pathname]);

  return (
    <Routes>
      <Route index path="/login" element={<LoginViewAutoDesign />} />
      {/* <Route index path="/" element={<LoginViewAutoDesign />} /> */}
      <Route path="/admin" element={<MasterAdmin />}>
        <Route index path="/admin" element={<DashboardScreen />} />
        <Route index path="/admin/instances" element={<InstanceList2 />} />
        <Route index path="/admin/users" element={<UserScreen />} />
        <Route index path="/admin/testing" element={<ImportTab />} />
        <Route
          index
          path="/admin/collected-data"
          element={<DataCollectionList />}
        />
        <Route
          index
          path="/admin/data-collection"
          element={<DataCollectionOld />}
        />
        <Route
          index
          path="/admin/manage-instance/:id/:station_id/:train_id"
          element={<InstanceDetails />}
        />
        <Route
          index
          path="/admin/manage-data/:id/:station_id/:train_id"
          element={<InstanceDetails />}
        />
        {/* New Data Visualization Routes */}
        <Route
          index
          path="/admin/data-visualizing"
          element={<div>Select a visualization option</div>}
        />{" "}
        {/* Placeholder for main visualization page */}
        <Route path="/admin/data-visualizing/radar" element={<RadarData />} />
        <Route path="/admin/data-visualizing/ambient" element={<Ambient />} />
        <Route index path="/admin/insights" element={<InsightsPage />} />
      </Route>
      <Route path="/user" element={<Master />}>
        <Route index path="/user/instances" element={<InstanceList2 />} />
        <Route
          index
          path="/user/manage-instance/:id/:station_id/:train_id"
          element={<InstanceDetails />}
        />
        <Route index path="/user" element={<DashboardScreen />} />
        <Route index path="/user/insights" element={<InsightsPage />} />
      </Route>
    </Routes>
  );
}

export default App;
