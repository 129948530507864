import { SetStateAction, useEffect, useState } from "react";
import axios from "axios";

import {
  Button,
  Calendar,
  Card,
  Empty,
  message,
  Spin,
  Statistic,
  StatisticProps,
} from "antd"; // Importing Calendar
import { useLocation } from "react-router-dom";
import {
  Clock,
  DownloadIcon,
  RefreshCcw,
  VideoIcon,
  WorkflowIcon,
} from "lucide-react";

import { FiSearch } from "react-icons/fi"; // Imported search icon
import { any, number } from "zod";
import { format } from "date-fns";
import { APIStandards } from "../../../../../lib/api_standards";
import { train_columns } from "../components/insights_column";
import { InsightsDataTable } from "../components/insights_datatable";
import dayjs from "dayjs";
import CountUp from "react-countup";
import { Separator } from "../../../../../components/seperator";
import GroundTruthButtonInsights from "../components/ground_truth_button_insights";
interface Train {
  map(
    arg0: (train: any) => import("react/jsx-runtime").JSX.Element
  ): import("react").ReactNode;
  train_id: string;
  container_count: number;
  wagon_count: number;
  in_time: string; // Time in "HH:mm:ss" format
  out_time: string; // Time in "HH:mm:ss" format
  direction: string;
  wagons: Wagon[];
  report_link: string;
  container_ground_truth_link: string;
  wagon_ground_truth_link: string;
}

interface Wagon {
  // wagon_id: number;
  wagon_no: number;
  wagon_type: string; // e.g., "single" or "multi"
  containers: Container[];
}

interface Container {
  container_code: string;
  container_sequence: any;
  container_sort: any;
  container_type: string; // e.g., "single" or "multi"
  container_position: string; // e.g., "U" or "L"
  ic_code: string; // e.g., "IC 67"
  dimension: string; // e.g., "2.9m 9'-6' HIGH"
  shipping_name: string; // e.g., "test_company"
  warning_hazard: string; // e.g., "Triangle", "Square", "Circle"
}

interface TrainList {
  train_id: number;
}
// interface TimelineItem {
//   label: string;
//   time: string;
// }

// const timelineItems: TimelineItem[] = [
//   { label: "Upload", time: "9:00 AM" },
//   { label: "Level 1", time: "11:30 AM" },
//   { label: "Level 2", time: "2:15 PM" },
//   { label: "Level 3", time: "4:45 PM" },
// ];
const formatter: StatisticProps["formatter"] = (value) => (
  <CountUp end={value as number} separator="," />
);
function InsightsPage() {
  const [TrainList, setTrainList] = useState<TrainList[]>([]);
  const [TrainDetails, setTrainDetails] = useState<Train | null>();
  const [selectedTrainData, setSelectedTrainData] = useState<Train | null>();
  const [isTrainListLoading, setIsTrainListLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = useLocation();
  const [refreshedTime, setLastRefreshedTime] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [trainData, setTrainData] = useState<any[]>([]);
  const [selectedTrain, setSelectedTrain] = useState<any>();
  const [firstTime, setfirstTime] = useState(true);
  const [timelineData, setTimeLineData] = useState([
    { label: "Upload", time: null },
    { label: "Level 1", time: null },
    { label: "Level 2", time: null },
    { label: "Report", time: null },
    { label: "Total", time: null },
  ]);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (userDetails.role === "admin" || userDetails.role === "dev") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userDetails]);
  const [isDev, setIsDev] = useState(false);
  useEffect(() => {
    if (userDetails.role === "dev") {
      setIsDev(true);
    } else {
      setIsDev(false);
    }
  }, [userDetails]);
  const fetchTrain = async (query: string) => {
    setTrainDetails(null);
    setIsLoading(true);
    setTrainData([]);

    // const date = format(selectedDate, "yyyy-MM-dd");
    // const trimmedDate = date.trim();
    try {
      const res = await axios.get(
        `${APIStandards.APP.TESTDATA}?trainId=${query}`
      );

      setTrainDetails(res.data.data);
      console.log("Train Data TrainDetails: ", res.data.data.train_id);
      setSearchTerm("");
      const transformedData = res.data.data.wagons.flatMap(
        (wagons: { [x: string]: any; containers: any[] }) =>
          wagons.containers.map((containers) => ({
            train_id: res.data.data.train_id,
            container_count: res.data.data.container_count,
            wagon_count: res.data.data.wagon_count,
            in_time: res.data.data.in_time,
            out_time: res.data.data.out_time,
            report_link: res.data.data.report_link,
            container_ground_truth_link:
              res.data.data.container_ground_truth_link,
            wagon_ground_truth_link: res.data.data.wagon_ground_truth_link,
            // wagon_id: wagons.wagon_id,
            wagon_no: wagons.wagon_no,
            wagon_type: wagons.wagon_type,
            container_code: containers.container_code,
            container_type: containers.container_type,
            container_position: containers.container_position,
            ic_code: containers.ic_code,
            dimension: containers.dimension,
            shipping_name: containers.shipping_name,
            warning_hazard: containers.warning_hazard,
            container_sequence: containers.container_sequence,
            container_sort: containers.container_sort,
            direction: containers.direction,
          }))
      );

      setTrainData(transformedData);
      setTimeLineData(res.data.data.level_data);
      console.log("Train Data transformedData: ", trainData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };
  //   const fetchTrainList = async (query: string) => {
  //     // setIsLoading(true);
  //     console.log("fetchTrainList date : ", query);
  //     const date = format(query, "yyyy-MM-dd");
  //     const trimmedDate = date.trim();
  //     try {
  //       const res = await axios.get(
  //         `${APIStandards.APP.TESTTRAINDATA}?date=${trimmedDate}`
  //       );

  //       setTrainList(res.data);
  //       console.log("Train Data TrainList: ", TrainList);
  //       //
  //       // setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching images:", error);
  //     } finally {
  //       // setIsLoading(false);
  //     }
  //   };
  useEffect(() => {
    if (TrainList && TrainList.length > 0 && firstTime) {
      handleRadioChange(TrainList[0]["train_id"]);
      setfirstTime(false);
    }
  }, [TrainList]);

  const fetchTrainList = async (target: any) => {
    setTrainList([]);
    setIsTrainListLoading(true);
    const date = format(target, "yyyy-MM-dd");
    const trimmedDate = date.trim();

    try {
      // Use Fetch API to make a request to the streaming endpoint
      const response = await axios.get(
        `${APIStandards.APP.TESTTRAINDATA}?date=${trimmedDate}&user_id=${userDetails.userID}`
      );

      if (!response.data) {
        throw new Error("ReadableStream not supported or empty response body.");
      }

      // // Process the stream using a TextDecoder
      // const reader = response.body.getReader();
      // const decoder = new TextDecoder("utf-8");

      // let done = false;
      // let buffer = ""; // Buffer to accumulate partial chunks

      // while (!done) {
      //   const { value, done: streamDone } = await reader.read();
      //   done = streamDone;

      //   if (value) {
      //     // Decode the chunk and append it to the buffer
      //     buffer += decoder.decode(value, { stream: true });

      //     // Split buffer into complete JSON objects by newline
      //     const lines = buffer.split("\n");
      //     buffer = lines.pop()!; // Save incomplete JSON for the next chunk

      //     // Parse and append each complete JSON object
      //     const parsedData = lines.map((line) => JSON.parse(line));
      //     setTrainList((prev: any) => [...prev, ...parsedData]);
      //     setIsTrainListLoading(false);
      //   }
      // }

      // // Handle any remaining data in the buffer (if it's a complete JSON object)
      // if (buffer.trim()) {
      //   setTrainList((prev: any) => [...prev, JSON.parse(buffer)]);
      // }
      setTrainList(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsTrainListLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails) {
      fetchTrainList(selectedDate.toISOString()); // Convert Date to ISO string
    }
  }, [selectedDate, userDetails]);
  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     fetchTrain(searchTerm);
  //     setSearchTerm(""); // Clear the search term after fetching
  //   }
  // }, [searchTerm]);
  const regenerate = async (trainId: any) => {
    const res = await axios.get(
      `${APIStandards.APP.REGENERATE_REPORT}?trainId=${trainId}`
    );
    const data = res.data;
    if (data) {
      if (data["success"]) {
        message.success("report queued for regeneration!");
        message.info("refresh after 10 seconds");
      }
    }
  };
  const handleInputChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchTerm(e.target.value);
  };

  const handleBlur = (value: string) => {
    if (value.trim()) {
      fetchTrain(value);
    }
  };

  const handleRadioChange = (trainID: any) => {
    // setSelectedTrain(trainID);
    setSelectedTrain(trainID);
    setSearchTerm(trainID); // Update the search input with the selected train ID
    fetchTrain(trainID);
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      setPage((prev) => prev + 1);
    }
  };

  const handleDateSelect = (date: any) => {
    setTrainList([]);
    setTrainDetails(null);
    setTrainData([]);
    console.log("Selected Date:", date.format("YYYY-MM-DD")); // Handle date selection logic here
    setSelectedDate(date);
  };
  const disableDatesBefore19Nov = (current: any) => {
    return (
      current &&
      current.isBefore(
        isAdmin ? dayjs("2024-11-19") : dayjs("2024-11-30"),
        "day"
      )
    );
  };
  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  function formatISTDate(dateString: any) {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  }
  return (
    <div className={`mx-4 py-10`}>
      <div className={`flex flex-col gap-5 min-h-[90vh] max-h-[90vh]`}>
        <div className="flex flex-row gap-5 min-h-[25vh] max-h-[25vh] w-[100%]">
          <div className="border p-6 rounded-lg shadow-md bg-gray-50 w-[50%]">
            <div className=" flex flex-col justify-evenly h-full">
              {isDev && TrainDetails && (
                <div className="flex flex-row gap-2 justify-end mb-1">
                  <div>
                    <Button icon={<WorkflowIcon />}>ReFlow</Button>
                  </div>
                  <div>
                    <Button
                      onClick={async () => {
                        await regenerate(TrainDetails.train_id);
                      }}
                      icon={<RefreshCcw />}
                    >
                      ReGenerate
                    </Button>
                  </div>
                </div>
              )}
              <div className=" flex flex-row justify-evenly">
                <div className="flex flex-col  ">
                  <span className="font-semibold text-xs text-gray-500">
                    Train ID:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.train_id
                      ? TrainDetails.train_id
                      : ""}
                  </span>
                </div>
                <Separator orientation="vertical" />
                <div className="flex flex-col  mb-6">
                  <span className="font-semibold text-xs text-gray-500">
                    Total Wagons:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.wagon_count
                      ? TrainDetails.wagon_count
                      : "No Result"}
                  </span>
                </div>
                <Separator orientation="vertical" />
                <div className="flex flex-col  mb-6">
                  <span className="font-semibold text-xs text-gray-500">
                    Total Containers:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.container_count
                      ? TrainDetails.container_count
                      : "No Result"}
                  </span>
                </div>
                <Separator orientation="vertical" />
                <div className="flex flex-col  mb-6">
                  <span className="font-semibold text-xs text-gray-500">
                    Direction:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.direction
                      ? TrainDetails.direction
                      : "No Result"}
                  </span>
                </div>
                <Separator orientation="vertical" />
                <div className="flex flex-col  mb-6">
                  <span className="font-semibold text-xs text-gray-500">
                    In Time:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.in_time
                      ? formatDate(TrainDetails.in_time)
                      : "No Result"}
                  </span>
                </div>
                <Separator orientation="vertical" />
                <div className="flex flex-col  mb-6">
                  <span className="font-semibold text-xs text-gray-500">
                    Out Time:
                  </span>
                  <span className=" font-semibold text-xs text-gray-700">
                    {TrainDetails && TrainDetails.out_time
                      ? formatDate(TrainDetails.out_time)
                      : "No Result"}
                  </span>
                </div>
              </div>
              <Separator />
              <div>
                <div className="w-full max-w-4xl mx-auto p-2">
                  <div className="relative">
                    <div className="h-1 w-full bg-gray-200 absolute top-5 left-0"></div>
                    <div className="flex justify-between items-center relative">
                      {timelineData.map((item, index) => (
                        <div key={index} className="flex flex-col items-center">
                          <div className="w-10 h-10 bg-[#0A4378] rounded-full flex items-center justify-center z-10">
                            <Clock className="w-5 h-5 text-white" />
                          </div>

                          <div className="mt-2 text-sm font-medium text-gray-600">
                            {item["label"]}
                          </div>
                          <div className="mt-1 text-xs text-gray-500">
                            {item["label"] == "Total"
                              ? item["time"]
                                ? item["time"]
                                : "not completed"
                              : item["time"]
                              ? item["label"] == "Report" ||
                                item["label"] == "Upload"
                                ? formatDate(item["time"])
                                : formatISTDate(item["time"])
                              : "not completed"}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Left side: Train ID List Table */}
          <div className="border p-4 rounded-lg shadow-md bg-gray-50 w-[20%]">
            {/* Train IDs with Radio Buttons */}
            <div className="mb-4">
              <h3 className="p-2 font-semibold text-base text-gray-700">
                Select Train ID
              </h3>
            </div>
            <Separator />
            <Spin spinning={isTrainListLoading}>
              <div
                className="overflow-y-scroll mt-4"
                style={{ maxHeight: "15vh" }}
              >
                {TrainList?.length < 1 && <Empty />}
                {TrainList?.length > 0 &&
                  TrainList?.map((train: any) => (
                    <label
                      key={train}
                      className="flex items-center mb-4 cursor-pointer"
                      style={{
                        borderRadius: "3px",
                        padding: "3px",
                      }}
                    >
                      <input
                        type="radio"
                        name="trainID"
                        value={train}
                        onChange={() => handleRadioChange(train)}
                        className="mr-2"
                      />
                      <span className="font-semibold text-gray-500">
                        {train}
                      </span>
                    </label>
                  ))}
              </div>
            </Spin>
          </div>

          <div
            className="bg-white w-[20%]"
            style={{ height: "25vh", overflow: "auto" }}
          >
            <Calendar
              fullscreen={false}
              onSelect={handleDateSelect}
              disabledDate={disableDatesBefore19Nov}
              className="rounded-sm  w-[100%]  "
            />
          </div>
          <div className="border  rounded-lg shadow-md bg-gray-50 w-[10%] ">
            {/* Train IDs with Radio Buttons */}
            <div className="flex flex-col justify-evenly h-full items-center">
              <div>
                <div className="mb-1">
                  <div className=" text-gray-700 items-center flex justify-center">
                    Report
                  </div>
                </div>
                <Separator />
                <Button
                  className="mt-2"
                  disabled={TrainDetails?.report_link == null}
                  onClick={() => {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: TrainDetails?.report_link,
                    }).click();
                  }}
                >
                  <DownloadIcon />
                </Button>
              </div>
              {/* <div>
                <div className="mb-1">
                  <div className=" text-gray-700 items-center flex justify-center">
                    <div className="flex flex-row gap-1 items-center">
                      <div className="text-[12px]">Container</div>
                      <div className=" text-[8px] text-gray-500">
                        (Ground Truth)
                      </div>
                    </div>
                  </div>
                </div>
                <Separator />
                <Button
                  disabled={TrainDetails?.container_ground_truth_link == null}
                  className="mt-2 items-center flex mx-auto"
                  onClick={() => {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: TrainDetails?.container_ground_truth_link,
                    }).click();
                  }}
                >
                  <VideoIcon />
                </Button>
              </div>
              <div>
                <div className="mb-1">
                  <div className=" text-gray-700 items-center flex justify-center">
                    <div className="flex flex-row gap-1 items-center">
                      <div className="text-[12px]">Wagon</div>
                      <div className=" text-[8px] text-gray-500">
                        (Ground Truth)
                      </div>
                    </div>
                  </div>
                </div>
                <Separator />
                <Button
                  className="mt-2 items-center flex mx-auto"
                  disabled={TrainDetails?.wagon_ground_truth_link == null}
                  onClick={() => {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: TrainDetails?.wagon_ground_truth_link,
                    }).click();
                  }}
                >
                  <VideoIcon />
                </Button>
              </div> */}
              <div>
                <GroundTruthButtonInsights
                  disabled={TrainDetails?.train_id == null}
                  instance={{
                    raw_count: 0,
                    container_id: TrainDetails?.train_id,
                    top_ground_truth: TrainDetails?.container_ground_truth_link,
                    bot_ground_truth: TrainDetails?.wagon_ground_truth_link,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Train Details Table with Scroll */}
        <div className="min-h-[65vh] max-h-[65vh]">
          <Spin spinning={isLoading}>
            <InsightsDataTable columns={train_columns} data={trainData} />
          </Spin>
        </div>
        {/* <div>
          {hasMore && (
            <div className="flex justify-center mt-4">
              <Button onClick={handleLoadMore} disabled={isLoading}>
                Load More
              </Button>
            </div>
          )}
        </div> */}
        <footer
          style={{
            textAlign: "center",
            backgroundColor: "#ffffff",
            color: "gray-300",
            position: "absolute",
            bottom: "0",
            left: "50%",
            fontSize: "14px",
          }}
        >
          <p>
            Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights
            Reserved.
          </p>
        </footer>
      </div>
    </div>
  );
}

export default InsightsPage;

/////////////////////////////////////
// import { SetStateAction, useEffect, useState } from "react";
// import axios from "axios";
// import { DataTable } from "./data-table";
// import { columns } from "./columns";
// import { Button, Spin } from "antd";
// import { useLocation } from "react-router-dom";
// import { RefreshCcw } from "lucide-react";
// import { APIStandards } from "../../../../lib/api_standards";
// import { format } from "date-fns";
// import { FiSearch } from 'react-icons/fi'; // Importing search icon
// import Calendar from 'react-calendar'; // Importing react-calendar library
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for the date picker

// function InsightsPage() {
//   const [InstanceList, setInstanceList] = useState<any>([]);
//   const [TrainDetails, setTrainDetails] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const pathname = useLocation();
//   const [refreshedTime, setLastRefreshedTime] = useState(0);
//   const [hasMore, setHasMore] = useState(false);
//   const [page, setPage] = useState(1);
//   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showCalendar, setShowCalendar] = useState(false);

//   const fetchTrain = async (query: string) => {
//     setIsLoading(true);
//     // const date = format(selectedDate, "yyyy-MM-dd");
//     // const trimmedDate = date.trim();
//     try {
//       const res = await axios.get(`${APIStandards.APP.TESTDATA}?trainId=${query}`);
//       setTrainDetails(res.data);
//       console.log("Train Data : ", TrainDetails)
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching images:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleBlur = () => {
//     if (searchTerm.trim()) {
//       fetchTrain(searchTerm);
//     }
//   };

//   const handleLoadMore = () => {
//     if (hasMore && !isLoading) {
//       setPage((prev) => prev + 1);
//     }
//   };

//   // const handleDateChange = (value: Date | null) => {
//   //   setSelectedDate(value); // This ensures that value is either a Date or null
//   // };

//   const handleDateChange = (date: any) => {
//     setSelectedDate(date);
//     setShowCalendar(false); // Hide calendar after selecting a date
//   };

//   return (
//     <div
//       className={`mx-4 py-10 ${
//         pathname.pathname === "/admin/collected-data"
//           ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
//           : ""
//       }`}
//     >
//       <div className="flex flex-row gap-5">
//         {/* Left Side (Train Details with Scrollbar) */}
//         <div className="border p-6 rounded-lg shadow-md bg-gray-50 w-1/2 max-h-[500px] overflow-y-auto">
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Train ID:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.train_id}</span>
//           </div>
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Count of Containers:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.container_count}</span>
//           </div>
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Count of Wagons:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.wagon_count}</span>
//           </div>
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">In Time:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.in_time}</span>
//           </div>
//           <div className="flex items-center">
//             <span className="font-semibold text-gray-700">Out Time:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.out_time}</span>
//           </div>
//         </div>

//         <div className="flex items-center py-4">
//         <Button
//           className="ml-96"
//           variant={"solid"}
//           onClick={() => {
//             setShowCalendar(!showCalendar);
//           }}
//         >
//           {" "}
//           {selectedDate
//             ? `Selected Date: ${format(selectedDate, "yyyy-MM-dd")}`
//             : "Select Date"}
//         </Button>
//         {showCalendar && (
//           <div className="fixed inset-0 flex items-center justify-center z-50">
//             {/* Overlay to close the popup when clicking outside */}
//             <div
//               className="absolute inset-0 bg-black opacity-50"
//               onClick={() => setShowCalendar(false)}
//             ></div>

//             {/* Calendar container */}
//             <div className="relative bg-white p-4 rounded-lg shadow-lg z-10">
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 dateFormat="yyyy-MM-dd" // Ensure correct format in picker
//                 inline={true}
//                 todayButton="Today" // Button to quickly select today
//                 className="text-center"
//                 calendarClassName="custom-calendar"

//               />
//             </div>
//           </div>
//         )}
//       </div>
//       </div>

//         {/* <div className="relative bg-white p-4 rounded-lg shadow-lg z-10">
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 dateFormat="yyyy-MM-dd" // Ensure correct format in picker
//                 inline={true}
//                 todayButton="Today" // Button to quickly select today
//                 className="text-center"
//                 calendarClassName="custom-calendar"

//               />
//             </div> */}
//         {/* Right Side (Calendar) */}
//         {/* <div className="border p-6 rounded-lg shadow-md bg-gray-50 w-1/2">
//           <Calendar
//             value={selectedDate}
//             onChange={handleDateChange}
//             className="react-calendar"
//           />
//         </div>
//       </div> */}

//       {/* Search Bar */}
//       <div>
//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Search Train..."
//             className="w-1/4 p-3 pl-10 border border-gray-300 rounded-md shadow-sm"
//             value={searchTerm}
//             onChange={handleInputChange}
//             onBlur={handleBlur}
//           />
//           <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
//         </div>
//       </div>

//       {/* Data Table */}
//       <div>
//         <DataTable columns={columns} data={InstanceList} />
//       </div>

//       {/* Load More Button */}
//       <div>
//         {hasMore && (
//           <div className="flex justify-center mt-4">
//             <Button onClick={handleLoadMore} disabled={isLoading}>
//               Load More
//             </Button>
//           </div>
//         )}
//       </div>
//     </div>

//   );
// }

// export default InsightsPage;
/////////////////////////////////////////////////////////

// import { SetStateAction, useEffect, useState } from "react";

// import axios from "axios";
// import { DataTable } from "./data-table";
// import { columns } from "./columns";
// import { Button, Spin } from "antd";
// import { useLocation } from "react-router-dom";
// import { RefreshCcw } from "lucide-react";
// import { APIStandards } from "../../../../lib/api_standards";
// import { format } from "date-fns";
// import { FiSearch } from 'react-icons/fi'; // Importing search icon

// // async function getData(): Promise<Instance[]> {

// //   // Fetch data from your API here.
// //   return InstanceList;
// // }

// // export default async function InstanceList2() {
// // const data = await getData();

// // return (
// //   <div className="container mx-auto py-10">
// //     <DataTable columns={columns} data={data} />
// //   </div>
// // );
// // }

// function InsightsPage() {
//   const [InstanceList, setInstanceList] = useState<any>([]);
//   const [TrainDetails, setTrainDetails] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const pathname = useLocation();
//   const [refreshedTime, setLastRefreshedTime] = useState(0);
//   const [hasMore, setHasMore] = useState(false);
//   const [page, setPage] = useState(1);
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   const [searchTerm, setSearchTerm] = useState('');

//   // useEffect(() => {
//   //   (async () => {
//   //     const res = await axios.get(
//   //       "http://localhost:8000/api/container_vision/instance/?date=all"
//   //     );
//   //     const data = res.data;
//   //     setInstanceList(data);
//   //     setIsLoading(false);
//   //   })();
//   // }, []);
//   const fetchTrain = async (query: string) => {
//     setIsLoading(true);
//     // const date = format(selectedDate, "yyyy-MM-dd");
//     // const trimmedDate = date.trim();
//     try {
//       const res = await axios.get(`${APIStandards.APP.TESTDATA}?trainId=${query}`);

//       // setHasMore(data.next !== null);
//       // const sortedItems = data.sort((a: any, b: any) => {
//       //   return (
//       //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
//       //   );
//       // });
//       // setInstanceList(sortedItems);
//       setTrainDetails(res.data);
//       console.log("Train Data : ",TrainDetails)

//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching images:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleBlur = () => {
//     if (searchTerm.trim()) {
//       fetchTrain(searchTerm);
//     }
//   };

//   // useEffect(() => {
//   //   fetchImages(page);

//   //   // const intervalId = setInterval(fetchImages, 60000);

//   //   // return () => clearInterval(intervalId);
//   // }, [page, selectedDate]);

//   // useEffect(() => {
//   //   const timerId = setInterval(() => {
//   //     setLastRefreshedTime((prevTime) => prevTime + 1);
//   //   }, 1000);

//   //   return () => clearInterval(timerId);
//   // }, []);
//   const handleLoadMore = () => {
//     if (hasMore && !isLoading) {
//       setPage((prev) => prev + 1);
//     }
//   };
//   return (
//     <div
//       className={` mx-4 py-10 ${
//         pathname.pathname === "/admin/collected-data"
//           ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
//           : ""
//       }`}
//     >
//       {/* {isLoading && (
//         <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
//           <Spin />
//         </div>
//       )} */}
//       {/* {!isLoading && ( */}
//         <div className={`flex flex-col gap-5 `}>
//           <div className="flex flex-row gap-5">
//             {/* <div className=" text-3xl font-bold tracking-wide">
//               Trains Summary ({InstanceList.length})
//             </div> */}
//             {/* <div className="flex flex-row gap-2 items-center">
//               <div>Last Refreshed {refreshedTime}s ago</div>
//               <div>
//                 <Button
//                   icon={<RefreshCcw />}
//                   onClick={() => {
//                     setInstanceList([]);
//                     fetchImages(page);
//                   }}
//                 >
//                   Refresh
//                 </Button>
//               </div>
//             </div> */}
//             <div className="border p-6 rounded-lg shadow-md bg-gray-50 w-1/2">
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Train ID:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.train_id}</span>
//           </div>
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Total Wagons:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.wagon_count}</span>
//           </div>
//           <div className="flex items-center mb-4">
//             <span className="font-semibold text-gray-700">Total Containers:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.container_count}</span>
//           </div>

//           <div className="flex items-center mt-4">
//             <span className="font-semibold text-gray-700">In Time:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.in_time}</span>
//           </div>
//           <div className="flex items-center mt-4">
//             <span className="font-semibold text-gray-700">Out Time:</span>
//             <span className="ml-3 text-gray-600">{TrainDetails.out_time}</span>
//           </div>
//         </div>

//         <div className="border p-6 rounded-lg shadow-md bg-gray-50 w-1/2">
//         </div>
//     </div>

//     <div>
//       <div className="relative">
//         <input
//           type="text"
//           placeholder="Search Train..."
//           className="w-1/4 p-3 pl-10 border border-gray-300 rounded-md shadow-sm"
//           value={searchTerm}
//           onChange={handleInputChange}
//           onBlur={handleBlur}
//         />
//         <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
//       </div>
//     </div>
//           <div>
//             {" "}
//             <DataTable
//               columns={columns}
//               data={InstanceList}
//               // setSelectedDate={setSelectedDate}
//               // selectedDate={selectedDate}
//             />
//           </div>
//           <div>
//             {hasMore && (
//               <div className="flex justify-center mt-4">
//                 <Button onClick={handleLoadMore} disabled={isLoading}>
//                   Load More
//                 </Button>
//               </div>
//             )}
//           </div>
//         </div>
//       {/* )} */}
//     </div>
//   );
// }

// export default InsightsPage;
